var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{staticClass:"p-fluid",attrs:{"visible":_vm.visible,"header":_vm.exibirHeader(),"closeOnEscape":!_vm.saving && !_vm.confirmDialog,"closable":!_vm.saving,"modal":true,"contentStyle":{ overflow: 'visible' },"containerStyle":{ width: '70vw' }},on:{"show":function($event){return _vm.onShow()},"onHide":function($event){return _vm.onHide()},"update:visible":function($event){return _vm.fecharDialog()}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{staticClass:"p-button-text",attrs:{"label":"Cancelar","icon":"pi pi-times"},on:{"click":function($event){return _vm.fecharDialog()}}}),_c('Button',{attrs:{"disabled":_vm.saving,"autofocus":""},on:{"click":function($event){return _vm.salvar()}}},[_vm._v("Salvar")])]},proxy:true}])},[_c('BlockUI',{attrs:{"blocked":_vm.saving}},[_c('Card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"block"},[_c('div',{staticClass:"inline-block col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"nome"}},[_vm._v("Quantidade de Parcelas")]),_c('InputText',{attrs:{"id":"nome","disabled":_vm.update,"type":"text"},model:{value:(_vm.consignatariaEntidadeVinculo.quantidadeParcelas),callback:function ($$v) {_vm.$set(_vm.consignatariaEntidadeVinculo, "quantidadeParcelas", $$v)},expression:"consignatariaEntidadeVinculo.quantidadeParcelas"}}),(
                _vm.submitted &&
                _vm.v$.consignatariaEntidadeVinculo.quantidadeParcelas.required
                  .$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo 'quantidade de parcelas' é obrigatório. ")]):(
                _vm.submitted &&
                _vm.v$.consignatariaEntidadeVinculo.quantidadeParcelas.maxLength
                  .$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O limite de caracteres foi ultrapassado! Máx: 3. ")]):_vm._e()],1),_c('div',{staticClass:"inline-block col-6 md:col-6"},[_c('Dropdown',{attrs:{"options":_vm.entidades,"optionLabel":"nome","filter":true,"placeholder":"Selecione uma entidade","showClear":true},scopedSlots:_vm._u([{key:"option",fn:function(slotProps){return [_c('div',{staticClass:"country-item"},[_c('div',[_vm._v(_vm._s(slotProps.option.nome))])])]}}]),model:{value:(_vm.consignatariaEntidadeVinculo.entidade),callback:function ($$v) {_vm.$set(_vm.consignatariaEntidadeVinculo, "entidade", $$v)},expression:"consignatariaEntidadeVinculo.entidade"}}),(
                _vm.submitted &&
                _vm.v$.consignatariaEntidadeVinculo.entidade.required.$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo de 'entidade' é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"inline-block col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"data_inicio_contrato"}},[_vm._v("Data de Início do Contrato")]),_c('Calendar',{attrs:{"id":"data_inicio_contrato","showIcon":true},model:{value:(_vm.dataInicioContrato),callback:function ($$v) {_vm.dataInicioContrato=$$v},expression:"dataInicioContrato"}}),(_vm.submitted && _vm.v$.dataInicioContrato.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo de 'data de inicio de contrato' é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"inline-block col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"data_fim_contrato"}},[_vm._v("Data de Fim do Contrato")]),_c('Calendar',{attrs:{"id":"data_fim_contrato","showIcon":true},model:{value:(_vm.dataFimContrato),callback:function ($$v) {_vm.dataFimContrato=$$v},expression:"dataFimContrato"}}),(_vm.submitted && _vm.v$.dataFimContrato.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo 'data de fim de contrato' é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"inline-block col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"numeroConvenio"}},[_vm._v("N° Convênio")]),_c('InputText',{attrs:{"id":"numeroConvenio","disabled":_vm.update,"type":"text"},model:{value:(_vm.consignatariaEntidadeVinculo.numeroConvenio),callback:function ($$v) {_vm.$set(_vm.consignatariaEntidadeVinculo, "numeroConvenio", $$v)},expression:"consignatariaEntidadeVinculo.numeroConvenio"}}),(
                _vm.submitted &&
                _vm.v$.consignatariaEntidadeVinculo.numeroConvenio.required
                  .$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo de 'número de convênio' é obrigatório. ")]):(
                _vm.submitted &&
                _vm.v$.consignatariaEntidadeVinculo.numeroConvenio.maxLength
                  .$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O limite de caracteres foi ultrapassado! Máx: 15. ")]):_vm._e()],1)])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }