export default class ConsignatariaEntidadeVinculo {
  constructor(
    id = null,
    quantidadeParcelas = null,
    consignataria = {},
    entidade = {},
    dataInicioContrato = null,
    dataFimContrato = null,
    numeroConvenio = '',
    status = null,
  ) {
    this.id = id
    this.quantidadeParcelas = quantidadeParcelas
    this.consignataria = consignataria
    this.entidade = entidade
    this.dataInicioContrato = dataInicioContrato
    this.dataFimContrato = dataFimContrato
    this.numeroConvenio = numeroConvenio
    this.status = status
  }
}
