export default class ConsignatariaEntidadeVinculo {
  constructor(http) {
    this._http = http
  }

  async getListaConsignatariaEntidadeVinculo() {
    const { data } = await this._http.get(
      `/api/consignataria-entidade-vinculo/list`,
    )
    return data
  }

  async getListaConsignatariaEntidadeVinculoPorConsigntariaId(consignatariaId) {
    const { data } = await this._http.get(
      `/api/consignataria-entidade-vinculo/consignataria/${consignatariaId}`,
    )
    return data
  }

  async salvarConsignatariaEntidadeVinculo(consignatariaEntidadeVinculo) {
    if (consignatariaEntidadeVinculo.id) {
      return await this._http.put(
        `/api/consignataria-entidade-vinculo`,
        consignatariaEntidadeVinculo,
      )
    } else {
      return await this._http.post(
        `/api/consignataria-entidade-vinculo`,
        consignatariaEntidadeVinculo,
      )
    }
  }
}
