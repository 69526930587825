<template>
  <Dialog
    :visible="visible"
    :header="exibirHeader()"
    :closeOnEscape="!saving && !confirmDialog"
    :closable="!saving"
    :modal="true"
    :contentStyle="{ overflow: 'visible' }"
    :containerStyle="{ width: '70vw' }"
    class="p-fluid"
    @show="onShow()"
    @onHide="onHide()"
    @update:visible="fecharDialog()">
    <BlockUI :blocked="saving">
      <Card>
        <template #content>
          <div class="block">
            <div class="inline-block col-6 md:col-6">
              <label for="nome" class="required">Quantidade de Parcelas</label>
              <InputText
                id="nome"
                v-model="consignatariaEntidadeVinculo.quantidadeParcelas"
                :disabled="update"
                type="text" />
              <div
                v-if="
                  submitted &&
                  v$.consignatariaEntidadeVinculo.quantidadeParcelas.required
                    .$invalid
                "
                class="p-error">
                O campo 'quantidade de parcelas' é obrigatório.
              </div>
              <div
                v-else-if="
                  submitted &&
                  v$.consignatariaEntidadeVinculo.quantidadeParcelas.maxLength
                    .$invalid
                "
                class="p-error">
                O limite de caracteres foi ultrapassado! Máx: 3.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <Dropdown
                v-model="consignatariaEntidadeVinculo.entidade"
                :options="entidades"
                optionLabel="nome"
                :filter="true"
                placeholder="Selecione uma entidade"
                :showClear="true">
                <template #option="slotProps">
                  <div class="country-item">
                    <div>{{ slotProps.option.nome }}</div>
                  </div>
                </template>
              </Dropdown>
              <div
                v-if="
                  submitted &&
                  v$.consignatariaEntidadeVinculo.entidade.required.$invalid
                "
                class="p-error">
                O campo de 'entidade' é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="data_inicio_contrato" class="required"
                >Data de Início do Contrato</label
              >
              <Calendar
                id="data_inicio_contrato"
                v-model="dataInicioContrato"
                :showIcon="true" />
              <div
                v-if="submitted && v$.dataInicioContrato.required.$invalid"
                class="p-error">
                O campo de 'data de inicio de contrato' é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="data_fim_contrato" class="required"
                >Data de Fim do Contrato</label
              >
              <Calendar
                id="data_fim_contrato"
                v-model="dataFimContrato"
                :showIcon="true" />
              <div
                v-if="submitted && v$.dataFimContrato.required.$invalid"
                class="p-error">
                O campo 'data de fim de contrato' é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="numeroConvenio" class="required">N° Convênio</label>
              <InputText
                id="numeroConvenio"
                v-model="consignatariaEntidadeVinculo.numeroConvenio"
                :disabled="update"
                type="text" />
              <div
                v-if="
                  submitted &&
                  v$.consignatariaEntidadeVinculo.numeroConvenio.required
                    .$invalid
                "
                class="p-error">
                O campo de 'número de convênio' é obrigatório.
              </div>
              <div
                v-else-if="
                  submitted &&
                  v$.consignatariaEntidadeVinculo.numeroConvenio.maxLength
                    .$invalid
                "
                class="p-error">
                O limite de caracteres foi ultrapassado! Máx: 15.
              </div>
            </div>
          </div>
        </template>
      </Card>
    </BlockUI>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fecharDialog()" />
      <Button :disabled="saving" autofocus @click="salvar()">Salvar</Button>
    </template>
  </Dialog>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'
import ConsignatariaEntidadeVinculo from '@/domain/ConsignatariaEntidadeVinculo.js'
import ConsignatariaEntidadeVinculoService from '@/service/ConsignatariaEntidadeVinculosService'
import EntidadeService from '@/service/EntidadeService'
import ConsignatariaService from '@/service/ConsignatariaService'

export default {
  props: {
    visible: { type: Boolean, required: true },
    update: { type: Boolean, required: true },
    propostaParcela: { type: Object, default: null },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      consignataria_id: this.$route.params.idConsignataria,
      consignatariaEntidadeVinculo: new ConsignatariaEntidadeVinculo(),
      entidades: [],
      vinculos: [],
      saving: false,
      submitted: false,
      confirmDialog: false,
      consignataria: {},
      dataInicioContrato: null,
      dataFimContrato: null,
    }
  },

  created() {
    this.entidadeService = new EntidadeService(this.$http)
    this.consignatariaEntidadeVinculoService =
      new ConsignatariaEntidadeVinculoService(this.$http)
    if (this.consignataria_id) {
      this.consignatariaService = new ConsignatariaService(this.$http)
    }
  },

  mounted() {
    this.carregarListaDeEntidades()
  },

  validations() {
    return {
      consignatariaEntidadeVinculo: {
        quantidadeParcelas: {
          required,
          maxLength: maxLength(3),
        },
        numeroConvenio: {
          required,
          maxLength: maxLength(15),
        },
        entidade: {
          required,
        },
      },
      dataInicioContrato: {
        required,
      },
      dataFimContrato: {
        required,
      },
    }
  },

  methods: {
    validate() {
      this.submitted = true
      this.v$.consignatariaEntidadeVinculo.$touch()
      if (this.v$.consignatariaEntidadeVinculo.$invalid) {
        return false
      }
      return true
    },

    exibirHeader() {
      return this.update ? 'Editar parcela' : 'Nova parcela'
    },

    limparCampos() {
      this.consignatariaEntidadeVinculo = {}
      this.dataInicioContrato = null
      this.dataFimContrato = null
    },

    fecharDialog() {
      this.limparCampos()
      this.$emit('update:visible', false)
    },

    onShow() {
      if (this.propostaParcela) {
        this.consignatariaEntidadeVinculo = JSON.parse(
          JSON.stringify(this.propostaParcela),
        )
        this.formatarData()
      }
    },

    salvar() {
      if (this.validate()) {
        this.atribuirDados()
        this.consignatariaEntidadeVinculoService
          .salvarConsignatariaEntidadeVinculo(this.consignatariaEntidadeVinculo)
          .then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'Parcela cadastrada com sucesso.',
              life: 10000,
            })
          })
          .catch((err) => {
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
          })
          .finally(() => {
            this.saving = false
            this.$emit('carregarDados')
            this.fecharDialog()
          })
      }
    },

    carregarListaDeEntidades() {
      if (this.consignataria_id) {
        this.consignatariaService
          .getConsignataria(this.consignataria_id)
          .then((res) => {
            this.consignataria = res

            this.entidadeService
              .getEntidadesPorConsignatariaId(this.consignataria.id)
              .then((res) => {
                this.entidades = res
              })
          })
      }
    },

    onHide() {},

    formatarData() {
      if (
        this.propostaParcela.dataInicioContrato != null &&
        this.propostaParcela.dataInicioContrato != ''
      ) {
        let dataInicio = new Date(
          this.propostaParcela.dataInicioContrato,
        ).toLocaleDateString('pt-BR')

        this.dataInicioContrato = dataInicio
      }

      if (
        this.propostaParcela.dataFimContrato != null &&
        this.propostaParcela.dataFimContrato != ''
      ) {
        let dataFim = new Date(
          this.propostaParcela.dataFimContrato,
        ).toLocaleDateString('pt-BR')

        this.dataFimContrato = dataFim
      }
    },

    atribuirDados() {
      this.consignatariaEntidadeVinculo.consignataria = this.consignataria
      this.consignatariaEntidadeVinculo.dataInicioContrato = new Date(
        this.dataInicioContrato,
      )

      this.consignatariaEntidadeVinculo.dataFimContrato = new Date(
        this.dataFimContrato,
      )
    },
  },
}
</script>
<style lang=""></style>
