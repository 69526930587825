<template>
  <div>
    <Card>
      <template #title>Parcelas</template>
      <template #content>
        <DataTable
          class="p-datatable-sm"
          :paginator="true"
          :rows="5"
          stripedRows
          :value="listaParcelas"
          :loading="loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} entidades"
          responsiveLayout="scroll">
          <template #empty> Nenhum contrato encontrada. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <template #header>
            <span class="mt-2 mr-2 p-input-icon-left">
              <Button
                label="Novo"
                icon="pi pi-plus"
                class="mt-2 mr-2 p-button-success float-right"
                @click="showFormParcela()" />
            </span>
            <Column
              header="Consignatária"
              field="consignataria.razaoSocial"></Column>
            <Column
              :sortable="true"
              field="entidade.nome"
              header="Entidade"></Column>
            <Column
              :sortable="true"
              field="dataInicioContrato"
              header="Data Inicio do Contrato">
              <template #body="{ data }">
                {{ data.dataInicioContrato | formatarData }}
              </template>
            </Column>
            <Column
              :sortable="true"
              field="dataFimContrato"
              header="Data Fim Contrato">
              <template #body="{ data }">
                {{ data.dataFimContrato | formatarData }}
              </template>
            </Column>
            <Column
              :sortable="true"
              field="numeroConvenio"
              header="Nº Convênio"></Column>
            <Column
              field="quantidadeParcelas"
              header="Quantidade Parcelas"></Column>
            <Column :sortable="true" field="status" header="Status">
              <template #body="{ data }">
                <Tag
                  v-if="data.status === 'ATIVO'"
                  class="mr-2"
                  severity="success"
                  value="Success"
                  >ATIVO</Tag
                >
                <Tag
                  v-else-if="data.status === 'CANCELADO'"
                  class="mr-2"
                  severity="warning"
                  value="Warning"
                  >CANCELADO</Tag
                >
                <Tag v-else severity="danger" value="Danger">EXPIRADO</Tag>
              </template>
            </Column>
            <Column header="Ações">
              <template #body="{ data }">
                <div>
                  <Button
                    v-tooltip.left="'Editar'"
                    :disabled="data.status === 'CANCELADO'"
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-submit mr-2"
                    @click="showFormParcela(data)" />
                </div>
              </template>
            </Column>
          </template>
        </DataTable>
      </template>
    </Card>
    <ContratoParcelaFormDialog
      :visible.sync="showContratoParcelaFormDialog"
      :update="update"
      :propostaParcela="contratoParcela"
      @carregarDados="carregarListaDeParcelas" />
  </div>
</template>
<script>
import moment from 'moment'
import ContratoParcelaFormDialog from './ContratoParcelaFormDialog.vue'
import ConsignatariaEntidadeVinculoService from '@/service/ConsignatariaEntidadeVinculosService'

export default {
  components: {
    ContratoParcelaFormDialog,
  },
  data() {
    return {
      consignataria_id: this.$route.params.idConsignataria,
      showContratoParcelaFormDialog: false,
      loading: false,
      update: false,
      listaParcelas: [],
      contratoParcela: {},
    }
  },

  created() {
    this.service = new ConsignatariaEntidadeVinculoService(this.$http)
  },

  mounted() {
    this.carregarListaDeParcelas()
  },

  methods: {
    showFormParcela(data) {
      if (data) {
        this.contratoParcela = data
        this.update = true
      } else {
        this.contratoParcela = {}
        this.update = false
      }
      this.showContratoParcelaFormDialog = true
    },

    limparCampoBusca() {
      this.campoBusca = ''
    },

    carregarListaDeParcelas() {
      this.loading = true
      this.service
        .getListaConsignatariaEntidadeVinculoPorConsigntariaId(
          this.consignataria_id,
        )
        .then((res) => {
          this.listaParcelas = res
          this.loading = false
        })
    },

    formatarData(data) {
      return moment(data).format('DD/MM/YYYY')
    },
  },
}
</script>
<style lang=""></style>
